<template>
  <a-spin tip="加载中" :spinning="isloading">
    <div class="tableList">
      <div style="padding: 10px">
        <a-form-model layout="inline">
          <a-form-model-item label="项目名称">
            <a-input placeholder="请输入" v-model="search.xiangmu" />
          </a-form-model-item>
          <a-form-model-item>
            <p-button type="primary" @click="searchList" name="搜索" />
          </a-form-model-item>
        </a-form-model>
      </div>

      <div>
        <a-button
          v-if="multi"
          type="primary"
          size="small"
          @click="confirmselect"
          style="margin-bottom: 10px"
          >确认选择</a-button
        >
        <a-table
          bordered
          rowKey="userName"
          size="small"
          :columns="list.columns"
          :pagination="list.pagination"
          @change="onPageChange"
          :dataSource="list.datas"
          :locale="{ emptyText: '暂无数据' }"
        >
          <span slot="action" slot-scope="action">
            <p-button
              v-if="!multi"
              name="选择"
              @click="selectuserinfo(action)"
              size="small"
              type="primary"
            />
          </span>
        </a-table>
      </div>
    </div>
  </a-spin>
</template>

  <script>
import specialfundApi from "@/api/work/specialfund";
const columns = [
  {
    key: "index",
    align: "center",
    title: "序号",
    width: 70,
    customRender: (text, record, index) => {
      return (
        (_this.list.pagination.current - 1) * _this.list.pagination.pageSize +
        index +
        1
      );
    },
  },
  {
    dataIndex: "orgName",
    align: "center",
    title: "编报部门",
  },
  {
    dataIndex: "postUserName",
    align: "center",
    title: "编报人",
  },
  {
    dataIndex: "reportTime",
    align: "center",
    title: "编报日期",
  },

  {
    dataIndex: "xiangmu",
    align: "center",
    title: "项目名称",
  },
  {
    dataIndex: "type",
    align: "center",
    title: "类型",
  },
  {
    dataIndex: "amount",
    align: "center",
    title: "预算总额",
  },
  {
    dataIndex: "shixiang",
    align: "center",
    title: "支出事项",
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
let _this;
export default {
  name: "searchSPFund",
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
        tableHeight: window.innerHeight - 310,
      },
      orgTree: [],
      selectedKeys: [],
      selectRows: [],
      selectRowKeys: [],
      rowSelection: null,
      search: {
        name: "",
      },
      isloading: false,
    };
  },
  props: {
    multi: {
      type: Boolean,
      default: false,
    },
    column: {
      type: String,
    },
  },
  mounted() {
    _this = this;
    _this.GetList();
  },
  methods: {
    onlyLeadChanged(v) {
      this.search.onlyLead = v;
      this.search.orgId = null;
      this.selectedKeys = [];
      _this.getOrgTree();
      _this.GetList();
    },
    confirmselect() {
      this.$emit("callback", this.selectRows, this.column);
    },
    onRowChange(selectedRowKeys, rows) {
      this.selectRowKeys = selectedRowKeys;
      var users = this.list.datas.map((item) => item.userName);

      for (var i = 0; i < users.length; ++i) {
        var user = users[i];
        for (var j = 0; j < this.selectRows.length; ++j) {
          if (this.selectRows[j].userName == user) {
            this.selectRows.splice(j, 1);
            break;
          }
        }
      }

      if (selectedRowKeys != null) {
        for (var k = 0; k < rows.length; ++k) {
          this.selectRows.push({
            userName: rows[k].userName,
            realName: rows[k].realName,
          });
        }
      }
    },
    onTypeSelect(selectKeys) {
      _this.search.orgId = selectKeys[0];
      _this.selectedKeys = selectKeys;
      _this.GetList();
    },
    GetList() {
      let _this = this;
      let param = {
        pageNow: _this.list.pagination.current,
        pageSize: _this.list.pagination.pageSize,
        status: 2,
        ..._this.search,
      };
      specialfundApi.getList(param).then((res) => {
        if (res.errorCode === _this.$commons.RespCode.success) {
          _this.list.datas = res.data.records;
          _this.list.pagination.total = Number(res.data.total);
        } else {
          this.$message.error(res.errorMsg);
        }
      });
      //获取列表
      // _this.isloading = true;
      // orgApi.getUsersForOrg(
      //     _this.list.pagination.current,
      //     _this.list.pagination.pageSize,
      //     {..._this.search}
      //   )
      //   .then((res) => {
      //     if (res.errorCode == _this.$commons.RespCode.success) {
      //       _this.list.datas = res.data.records;
      //       _this.selectRowKeys=[];
      //       _this.list.datas.forEach(item=>{
      //         var f = _this.selectRows.filter(i=>i.userName==item.userName);
      //         if(f.length>0){
      //           _this.selectRowKeys.push(item.userName)
      //         }
      //       })
      //       _this.list.pagination.total = Number(res.data.total);
      //     } else {
      //       _this.$message.error(res.errorMsg);
      //     }
      //     _this.isloading = false;
      //   })
      //   .catch(() => {
      //     _this.isloading = false;
      //   });
    },
    onPageChange(page) {
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      this.GetList();
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.GetList();
    },
    selectuserinfo(user) {
      this.$emit("callback", user, this.column);
    },
  },
};
</script>
  
  <style>
</style>
  
  